import React, {useState, useEffect} from 'react';
import "./style.styl";

export default function EmailMarketingPage(props) {
    return (
        <div className="container draft-view">
           <h1>Email Marketing</h1>
           <p className="text-muted">Email marketing has three main aims: increase brand awareness, re-engage subscribers/customers, and customer retention. You may be already familiar with these three conversion-driven actions and you may also be well aware of their importance in the success of your business. Email marketing may not be the first thing on your mind when you hear these things. You have probably used different marketing strategies with varying levels of success. But what if there is one high-performing marketing strategy that you are missing out on?</p>
        <p className="text-muted"></p>
           <h2>Our Email Marketing Process:</h2>
           <ol>
              <li className="text-muted">Research and insights</li>
              <li className="text-muted">Lead generation</li>
              <li className="text-muted">CRM integration</li>
              <li className="text-muted">List segmentation</li>
              <li className="text-muted">Content development</li>
              <li className="text-muted">Email design</li>
              <li className="text-muted">Spam testing</li>
              <li className="text-muted">CAN-SPAM compliance audit</li>
              <li className="text-muted">Sending campaigns</li>
              <li className="text-muted">Tracking and Marketing Automation</li>
              <li className="text-muted">Retargeting – email and PPC ads</li>
              <li className="text-muted">Measurement and Optimization</li>
           </ol>
           <p className="text-muted">Email marketing with Marketing Automation is the best marketing strategy for not only brand awareness, re-engagement, and retention but also increase sales conversions and ROI.</p>
           <p className="text-muted"><br /></p>
           <h3 >Here are the reasons why email marketing is so effective:</h3>
           <p className="text-muted"></p>
           <h5>Email is Universal</h5>
           <p className="text-muted">Almost everyone uses email. Currently, there are around 240 million email users in the US. This is projected to increase to more than 250 million by 2020. These numbers are important since according to a survey, sending or reading email is the number one online activity of adult internet users. This accounts for 85% of respondents. Another survey indicates that 78% of teenagers use email as a part of their daily lives. Overall, 99% of email users in the US check their email up to 20 times a day.</p>
           <p className="text-muted"></p>
           <h5>Email is Easy</h5>
           <p className="text-muted">It’s one of the easiest ways to reach and engage potential customers. The delivery rate for email is very high – about 98.3% (overall). In comparison, social media provides limited reach to existing followers. Even perfectly timed posts and private messages won’t achieve the same delivery rate as emails. Email is also the preferred channel for promotional messages (77%).</p>
           <p className="text-muted"></p>
           <h5>Email is Economical</h5>
           <p className="text-muted">Email marketing is a cost-effective way to boost your store and/or website traffic. Newsletters are not only limited to digital traffic. It can also drive in-store traffic through the in-store event and offer announcements. One of the best uses of email marketing is building brand awareness. Email marketing bolsters long-term relationships between brands and customers without costing a lot of resources.</p>
           <p className="text-muted"></p>
           <h5>Email is Effective</h5>
           <p className="text-muted">It’s the most effective digital marketing strategy. Email’s ROI is $40 per $1 spent (3800%). Trailing behind that is SEO with an ROI of $22.24 per $1 spent, internet display with $19.72, and keyword ads with $17.</p>
           <p className="text-muted"></p>
           <h5>Emails Drive Sales</h5>
           <p className="text-muted">Emails drive more sales conversions. On average, email receives an open rate of 21.80% and a click-through rate of 2.78%. Shopping cart abandonment emails have the highest open rate (46.6%), click-through rate (28.7%), and conversion rate (5%). This is impressive since this conversion rate is higher than marketing channels like search and social.</p>
           <p className="text-muted"></p>
           <h5>Emails Generate Leads</h5>
           <p className="text-muted">Different businesses across unrelated industries have different priorities. It is agreed, though that lead generation (29%) is email marketing’s top benefit. This is followed by improved conversion rates (17%), identification of quality leads (17%), and improved sales (15%).</p>
           <p className="text-muted"><br /></p>
           <h3 >What Professional Email Marketing Management Services Can do for You</h3>
           <p className="text-muted">Even with a simple goal, launching an email marketing campaign isn’t as straightforward as you think. There’s a lot that goes into every campaign and managing a campaign is a continuous process. Aside from the obvious benefit of having experts on your team, email marketing management services bring more to the table than you think.</p>
           <p className="text-muted">Email marketing agencies will have people working on specific tasks, enabling them to focus on providing the best possible solutions for clients.</p>
           <p className="text-muted"><br /></p>
           <h3 >Email Marketing Services Frees Up Internal Resources</h3>
           <p className="text-muted">It takes a team to make an email marketing campaign running. There are a lot of tasks to focus on – tasks that require specific skills that not one person can just have. It’s not enough for one person in the team to be knowledgeable in email marketing. There are a lot of things to consider including:</p>
           <ul>
              <li className="text-muted">Automation</li>
              <li className="text-muted">Responsive email design</li>
              <li className="text-muted">Email segmentation and personalization</li>
              <li className="text-muted">Email subject line and copy</li>
              <li className="text-muted">List size and quality</li>
              <li className="text-muted">Offer value proposition</li>
              <li className="text-muted">Landing pages</li>
           </ul>
           <p className="text-muted"><br /></p>
           <h2 >We Specialize in Email Marketing</h2>
           <p className="text-muted">Email marketing requires someone that has in-depth knowledge of both its technical and strategical requirements. Most of the time, even knowledge isn’t enough, one needs to have experience in a specific type of marketing to almost instinctively know how campaigns work, what makes them work, why they work, when they work, and where they work.</p>
           <p className="text-muted">Even agencies that don’t specialize in email marketing has a team of experts that have several years’ worth of experience in the industry. Hiring someone with the same experience and expertise is expensive and you can have their services for lesser than a thousand dollars a month.</p>
           <p className="text-muted"></p>
           <p  className="text-muted">Our goal is your success. Email marketing is a powerful strategy to increase your site’s traffic and conversions. But in order for it to become successful, you’ll need a team of experts that will help your endeavors every step of the way.&nbsp;<span style={{fontFamily: 'Poppins, Helvetica, sans-serif'}} className="text-muted">Wescale will provide you with the complete email marketing package – and more. As one of the top digital marketing and web design firms in the country, Wescale offers solid strategies and customized solutions presented in a creative and innovative approach.</span></p>

        </div>
    );
}
